.menu-mobile {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -120%;
  width: 100%;
  background-color: $white;
  z-index: 5;
  color: $lightBlack;
  padding: 0;
  margin: 0;
  transition: 0.4s ease-in-out;
  padding-top: 107px;
  // padding-bottom: 213px;
  padding-bottom: 193px;

  @include respond-max('mobile') {
    padding-top: 56px;
    padding-bottom: 133px;
  }
  &.menu-mobile--open {
      right: 0;
      transition: 0.7s ease-in-out;
      @include respond-min('tablet-small') {
        display: none;
      }
  }
  &__link-item {
      display: inline-block;
      font-style: normal;
      // font-weight: 500;
      line-height: normal;
      font-size: 32px;
      line-height: 1.5;
      color: $lightBlack;
      text-decoration: none;
      transition: 0.2s ease;
      text-align: center;

      @include respond-max('mobile') {
        font-size: 24px;
      }

      &:hover{
          color: $orange1;
          text-decoration: none;
          transition: 0.3s ease;
      }
      &:focus{
          color: $orange1;
          text-decoration: none;
          transition: 0.3s ease;
      }
      &:active{
          color: $orange1;
          text-decoration: none;
          transition: 0.3s ease;
      }
  }
  &__links-block {
      // padding: 28px 40px 28px 40px;
      padding: 38px 40px 80px 40px;
      text-align: center;
      max-height: 100%;
      overflow-y: auto;
      width: 100%;
      @include respond-max('mobile') {
        padding: 18px 40px 30px 40px;
      }
  }
  &__link-item-wrap {
    /* margin: 20px 0; */
    &:not(:last-child){
      margin-bottom: 40px;
      @include respond-max('mobile') {
        margin-bottom: 22px;
      }
    }
  }

  &__bottom-box {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      text-align: center;
      // padding-top: 20px;
      padding: 0px 41px 56px 41px;
      @include respond-max('mobile') {
        padding: 0px 16px 24px 16px;
      }
  }
  &__box {
      display: block;
      max-height: 100%;
      // overflow-y: auto;
      position: absolute;
      top: 107px;
      left: 0;
      right: 0;
      bottom: 193px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @include respond-max('mobile') {
        top: 56px;
        bottom: 133px;
      }
  }
  &__phone-wrap {
    margin-bottom: 25px;
    @include respond-max('mobile') {
      margin-bottom: 17px;
    }
  }
  &__phone {
    font-size: 32px;
    line-height: 1.5;
    text-align: center;
    color: $orange1;
    @include respond-max('mobile') {
      font-size: 24px;
    }
  }
  &__search-btn {
    width: 100%;
    height: 64px;
    @include respond-max('mobile') {
      height: 56px;
    }
  }
}

html.html-menu-open {
  overflow: hidden;
}
body.body-menu-open {
  overflow: hidden;
  // overflow-y: scroll;
}
