.grid-scr {
  position: relative;
  background-color: #333333;
  color: $white;
  &__top {
    position: relative;
    padding-top: 111px;
    padding-bottom: 70px;
    @include respond-max('tablet') {
      padding-bottom: 30px;
    }
    @include respond-max('tablet-small') {
      padding-top: 56px;
      padding-bottom: 35px;
    }
    @include respond-max('mobile') {
      padding-top: 40px;
      padding-bottom: 1px;
    }
  }
  &__top-row {
    display: flex;
    flex-wrap: wrap;
    @include respond-max('tablet-small') {
      justify-content: center;
      margin-left: -16px;
      margin-right: -16px;
    }
    @include respond-max('mobile') {
      display: block;
    }
  }
  &__top-col {
    margin-bottom: 30px;
    @include respond-min('tablet-small') {
      &:not(:last-child){
        margin-right: 102px;
      }
    }
    @include respond-max('tablet-small') {
      margin-bottom: 33px;
      padding-left: 49px;
      padding-right: 49px;
    }
    @include respond-max('mobile') {
      margin-bottom: 25px;
    }
  }

  &__title {
    color: $orange1;
    font-size: 32px;
    line-height: 1.5;
    font-weight: 300;
    margin: 0;
    margin-bottom: 57px;

    @include respond-max('mobile') {
      font-size: 20px;
      line-height: 1.4;
      margin-right: -1px;
      margin-bottom: 32px;
    }
  }
  &__bottom {
    padding-top: 38px;
    padding-bottom: 80px;
    @include respond-max('mobile') {
      padding-top: 14px;
      padding-bottom: 40px;
    }
  }
}

.feature {
  @include respond-max('mobile') {
    text-align: center;
  }
  &__num {
    font-weight: bold;
    font-size: 48px;
    color: $orange1;
    display: inline-block;
    vertical-align: middle;
    margin-right: 21px;
    sup {
      top: -0.7em;
      font-size: 60%;
    }
    @include respond-max('tablet-small') {
      font-size: 40px;
    }
    @include respond-max('mobile') {
      margin-right: 0;
      font-size: 24px;
    }
  }
  &__text {
    font-size: 16px;
    line-height: 1.5;
    display: inline-block;
    vertical-align: middle;
    span {
      display: block;
    }
    @include respond-max('tablet-small') {
      font-size: 14px;
      text-transform: uppercase;
    }
    @include respond-max('mobile') {
      display: block;
      font-size: 12px;
      line-height: 1.2;
      margin-top: 9px;
    }
  }
}

.grid-feature {
  font-weight: normal;
  font-size: 18px;
  line-height: 1.5;
  color: $white;

  @include respond-max('tablet-small') {
    font-size: 16px;
    line-height: 1.4;
  }
  &__preview {
    display: block;
    position: relative;
    display: flex;
    align-items: flex-end;
    background-color: #000000;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 290px;
    @include respond-max('tablet-small') {
      min-height: 208px;
    }
    @include respond-max('mobile') {
      min-height: 220px;
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 13.28%, #000000 100%);
    }
  }
  &__preview-text {
    position: relative;
    padding: 35px 48px;
    @include respond-max('tablet-small') {
      line-height: 1.5;
      padding: 27px 24px;
    }
    @include respond-max('mobile') {
      font-size: 16px;
      line-height: 1.4;
      padding: 27px 24px;
    }
  }
  &__col {
    display: flex;
    @include respond-max('tablet-small') {
      @include respond-min('mobile') {
        &:nth-child(6) {
          order: 1;
        }
        &:nth-child(8) {
          order: 1;
        }
        &:nth-child(7) {
          order: 2;
          .grid-feature__sm-icon {
            position: absolute;
            bottom: -8px;
            right: 8px;
          }
        }
      }
    }
  }
  &__card {
    // display: block;
    position: relative;
    width: 100%;
    background-color: $orange1;
    color: $white;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    @include respond-max('tablet-small') {
      min-height: 208px;
    }
    @include respond-max('mobile') {
      min-height: 136px;
    }
    &.style-white {
      background-color: $white;
      color: $orange1;
      @include respond-max('mobile') {
        min-height: 168px;
      }
    }
  }
  &__card-text {
    padding: 26px 24px 9px 24px;
    position: relative;
    z-index: 1;

    @include respond-max('tablet-small') {
      padding: 23px 23px 9px 23px;
    }
  }
  &__bg-icon {
    width: 225px;
    height: 225px;
    position: absolute;
    bottom: -40px;
    right: -40px;
    @include respond-max('tablet-small') {
      width: 152px;
      height: 152px;
      position: absolute;
      bottom: -32px;
      right: -32px;
    }
    @include respond-max('mobile') {
      width: 120px;
      height: 120px;
      position: absolute;
      bottom: 7px;
      right: -50px;
    }
    .icon {
      width: 100%;
      height: 100%;
      position: relative;
      &.icon-grid-big-fire {
        @include respond-max('mobile') {
          transform: scaleX(-1);
          left: 6px;
        }
      }
      &.icon-grid-big-time {
        @include respond-min('tablet-small') {
          top: 8px;
          left: 8px;
        }
      }
    }
    &.position-center {
      bottom: 0;
      right: 50%;
      width: 152px;
      height: 152px;
      margin-right: -76px;

      @include respond-max('tablet-small') {
        @include respond-min('mobile') {
          width: 152px;
          height: 152px;
          bottom: -32px;
          right: -32px;
          margin-right: 0;
        }
      }
      @include respond-max('mobile') {
        margin-right: 0;
        width: 120px;
        height: 120px;
        position: absolute;
        bottom: 7px;
        right: -56px;
      }
    }

  }
  &__sm-icon {
    width: 96px;
    min-width: 96px;
    height: 96px;
    margin: 0 auto;
    margin-bottom: 24px;
    margin-top: auto;

    @include respond-max('tablet-small') {
      width: 64px;
      min-width: 64px;
      height: 64px;
      margin-bottom: 16px;
    }
    @include respond-max('mobile') {
      width: 48px;
      min-width: 48px;
      height: 48px;
    }

    .icon {
      width: 100%;
      height: 100%;
      position: relative;
    }

  }
}
