@include respond-min('tablet-small') {
  .level {
    min-height: 844px;

    position: relative;

    &-content {
      width: calc(100% - 365px);
      padding-right: 30px;
      max-width: 663px;
    }

    &-tab {
      width: 100%;
    }

    &-button {
      position: absolute;
      // right: 0;
      // top: 593px;
      width: 365px;
      left: 100%;
      top: 0%;
      margin-left: 30px;
      margin-top: 3px;

      .button {
        width: 100%;
      }
    }

    &-nav {
      width: 365px;
      position: absolute;
      right: 0;
      top: 4px;
    }

    &-image {
      min-height: 407px;

      &:not(:last-child) {
        margin-bottom: 34px;
      }
    }
    &-position-wrapper {
      position: relative;
      width: 100%;
    }
  }
}
@include respond-min('tablet') {
  .level {
    &-button {
      margin-left: 162px;
    }
  }
}

.level {


  &-tab {
    &:not(.is_show) {
      display: none;
    }
    @include respond-max('tablet-small') {
      padding-top: 21px;
    }
    @include respond-max('mobile') {
      padding-top: 1px;
    }
  }

  &-title {
    font-weight: bold;
    font-size: 64px;
    line-height: 79px;

    @include respond-max('mobile') {
      font-size: 32px;
      line-height: 1.2;
    }

    &:not(:last-child) {
      margin-bottom: 44px;
      @include respond-max('mobile') {
        margin-bottom: 13px;
      }
    }
  }

  &-image {
    @include respond-max('tablet-small') {
      // width: 429px;
      margin: 0 auto;
      margin-bottom: 36px;
    }
    @include respond-max('mobile') {
      max-width: 100%;
      display: block;
      margin-bottom: 7px;
    }
  }

  &-nav {

    &__row {
      @include respond-max('tablet-small') {
        display: flex;
        .level-nav__elem:first-child {
          margin-left: 0;
        }
      }
    }
    &__size-wrapper {
      overflow-x: auto;
      @include respond-min('tablet-small') {
        margin-right: 0 !important;

      }
      @include respond-max('tablet-small') {
        margin-left: -8px;
        margin-right: -8px;
      }
      // @include respond-max('mobile') {
      //   margin-right: -16px;
      //   margin-left: -16px;
      // }
      @include respond-max('mobile') {
        margin-right: -16px;
      }
    }
    &__elem {
      height: 64px;
      font-size: 16px;
      color: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #CCCCCC;
      transition: border-color .2s ease-out, color .2s ease-out, background-color .2s ease-out;
      cursor: pointer;

      &:hover {
        background-color: #ccc;
      }

      &-col {
        &:not(:last-child) {
          margin-bottom: 24px;
        }
      }

      &.is_active {
        background-color: #000;
        color: #fff;
        border-color: #000;
      }

      @include respond-max('tablet-small') {
        min-width: 200px;
        padding: 0 72px 0 71px;
        white-space: nowrap;
        &-col {
          // margin-right: 16px;
          padding-left: 8px;
          padding-right: 8px;
          &:last-child {
            padding-right: 16px;
          }
        }
      }
      @include respond-max('mobile') {
        min-width: 145px;
        padding: 0 40px 0 39px;
        height: 40px;
        font-size: 14px;
        &-col {
          // margin-right: 16px;
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }

  &-info {
    display: flex;

    @include respond-max('mobile') {
      display: flex;
      flex-wrap: wrap;
    }

    &:not(:last-child) {
      margin-bottom: 42px;
      @include respond-max('mobile') {
        margin-bottom: 25px;
      }
    }
  }

  &-info__item {
    &:not(:last-child) {
      margin-right: 40px;
      @include respond-max('mobile') {
        margin-right: 52px;
        margin-bottom: 21px;
      }
    }

    p {
      font-size: 18px;
      line-height: 150%;

      @include respond-max('tablet') {
        font-size: 16px;
      }
      @include respond-max('tablet-small') {
        font-size: 18px;
      }
      @include respond-max('mobile') {
        font-size: 14px;
      }

      &:not(:last-child) {
        margin-bottom: 16px;
        @include respond-max('mobile') {
          margin-bottom: 6px;
        }
      }
    }
    &:last-child {
      @include respond-max('mobile') {
        flex: 0 0 100%;
      }
    }
  }

  &-info__title {
    font-weight: bold;
    font-size: 32px;
    line-height: 28px;

    @include respond-max('tablet') {
      font-size: 26px;
    }
    @include respond-max('tablet-small') {
      font-size: 32px;
    }
    @include respond-max('mobile') {
      font-size: 20px;
    }

    sup {
      font-size: 20px;
    }
  }

  &-text {
    font-size: 16px;
    line-height: 150%;
    @include respond-max('mobile') {
      font-size: 14px;
      line-height: 1.4;
    }
  }

  &-image {
    img {
      max-width: 100%;
    }
  }

  &-bottom {
    @include respond-max('tablet-small') {
      padding-right: 80px;
    }
    @include respond-max('mobile') {
      padding-right: 0;
    }
  }
  &-button {
    @include respond-max('tablet-small') {
      margin-top: 48px;
      .button {
        width: 100%;
      }
    }
    @include respond-max('mobile') {
      margin-top: 24px;
    }

  }
}
