.plug-scr {
  background-color: #151515;
  background-image: url(../images/plug-scr-01.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  @include respond-max('tablet-small') {
    overflow: hidden;
    background-image: url(../images/plug-scr-01-mobile.jpg);
    // background-size: contain;
    background-size: 100% auto;
    background-position: top center;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(179.98deg, rgba(32, 32, 32, 0) 7.29%, rgba(21, 21, 21, 0.9) 89.51%);
    // z-index: 1;
    @include respond-max('tablet-small') {
      background: linear-gradient(180deg, rgba(173, 173, 173, 0) 11.37%, rgba(21, 21, 21, 0.9) 83.7%);
      bottom: auto;
      padding-bottom: 136%;
      // height: 136vw;
    }
  }
  &:after {
    @include respond-max('tablet-small') {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    // bottom: 0;
    // z-index: 1;
      bottom: auto;
      padding-bottom: 136%;
      margin-top: 136vw;
      background-color: rgba(21, 21, 21, 0.9);
      // height: 136vw;
    }
  }
  &__arc {
    // content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    // left: 0;
    background-image: url(../images/svg/arc.svg);
    // background-size: contain;
    // background-size: auto 100%;
    background-size: auto 1026px;
    background-position: 211% 0%;
    background-repeat: no-repeat;
    width: 1126px;
    right: 50%;
    // margin-right: -513px;
    margin-right: -1069px;
    left: auto;
    z-index: 1;
    opacity: 0.7;
    @include respond-max('desktop') {
      margin-right: -1069px;
    }
    @include respond-max('tablet-small') {
      background-image: url(../images/svg/arc-mobile.svg);
      width: 526px;
      background-size: auto 1024px;
      background-position: center;
      margin-right: -630px;
      opacity: 0.3;
    }
    // @include respond-max('mobile') {
      // margin-right: -759px;
    // }
  }
  &__position-wrapper {
    position: relative;
    z-index: 2;
    width: 100%;
    padding-bottom: 103px;
    padding-top: 20px;
    @include respond-max('tablet') {
      padding-bottom: 60px;
    }
    @include respond-max('tablet-small') {
      padding-top: 38px;
      padding-bottom: 38px;
    }
  }
  &__title {
    font-weight: 400;
    font-size: 80px;
    line-height: 1.2;
    text-transform: uppercase;
    color: $white;
    margin: 0;
    margin-bottom: 10px;
    @include respond-max('tablet') {
      font-size: 60px;
    }
    @include respond-max('tablet-small') {
      font-size: 40px;
    }
    @include respond-max('mobile') {
      max-width: 330px;
    }
  }
  &__phone {
    display: inline-block;
    font-weight: 400;
    font-size: 48px;
    line-height: 1.2;
    color: $orange1;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: $orange1;
    }
    @include respond-max('tablet') {
      font-size: 40px;
    }
    @include respond-max('tablet-small') {
      font-size: 32px;
    }
  }
  &__text {
    font-weight: normal;
    font-size: 18px;
    line-height: 1.5;
    color: #FFFFFF;
    padding-top: 3px;
    padding-bottom: 3px;
    p {
      &:not(:last-child){
        margin-bottom: 27px;
        @include respond-max('tablet-small') {
          margin-bottom: 20px;
        }
      }
    }
    @include respond-max('tablet') {
      font-size: 16px;
    }
    @include respond-max('tablet-small') {
      font-size: 14px;
      line-height: 1.45;
    }
  }
  &__phone-wrapper {
    margin-top: 35px;
    @include respond-max('tablet-small') {
      margin-top: 17px;
      margin-bottom: 13px;
    }
  }
}

.plug-header {
  padding: 40px 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  z-index: 2;
  @include respond-max('tablet-small') {
    padding: 24px 0;
  }
  .icon-logo {
    display: block;
    width: 228px;
    min-width: 228px;
    height: 88px;
    @include respond-max('tablet-small') {
      display: block;
      width: 125px;
      min-width: 125px;
      height: 48px;
    }
  }
  .icon-logo247 {
    display: block;
    width: 289px;
    min-width: 289px;
    height: 88px;
    @include respond-max('tablet-small') {
      display: block;
      width: 158px;
      min-width: 158px;
      height: 48px;
    }
  }
  &__logo {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 50vw;
      background-image: url(../images/svg/logo-pattern.svg);
      background-size: contain;
      background-position: center right;
      background-repeat: repeat-x;
    }
  }
}
