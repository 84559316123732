.news-history {
  padding-top: 2px;
  @include respond-max('tablet-small') {
    padding-top: 80px;
  }
  @include respond-max('mobile') {
    padding-top: 46px;
  }
  // &__info {
  //   // padding-top: 117px;
  // }
  &__border-top {
    background-color: #EDEDED;
    height: 1px;
    margin-bottom: 117px;

    @include respond-max('tablet-small') {
      height: 0;
      margin-bottom: 0;
      background-color: transparent;
    }
  }
  &__photo {
    margin-top: 3px;
    @include respond-max('tablet-small') {
      padding-left: 27px;
    }
    @include respond-max('mobile') {
      padding-left: 0;
      width: 100%;
      height: auto;
      padding-bottom: 100%;
      position: relative;
      display: block;
      margin-top: 24px;
      margin-bottom: 22px;
    }
    img {
      display: inline-block;
      max-width: 100%;
      height: auto;
      line-height: 1;
      vertical-align: middle;
      @include respond-max('tablet-small') {
        @include respond-min('mobile') {

        }
        height: 293px;
        width: 100%;
        object-fit: cover;
        object-position: center bottom;
      }
      @include respond-max('mobile') {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
  &__info {
    @include respond-max('tablet-small') {
      @include respond-min('mobile') {
        margin-right: -10px;
      }
    }
  }
  &__title {
    font-size: 64px;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    color: $orange1;
    margin-bottom: 131px;
    @include respond-max('tablet') {
      margin-bottom: 100px;
    }
    @include respond-max('tablet-small') {
      font-size: 40px;
      margin-bottom: 76px;
    }
    @include respond-max('mobile') {
      font-size: 32px;
      margin-bottom: 26px;
    }
  }
  &__title--location {
    margin-top: 119px;
    margin-bottom: 58px;
    @include respond-max('tablet-small') {
      margin-top: 95px;
      margin-bottom: 25px;
    }
    @include respond-max('mobile') {
      margin-top: 16px;
      margin-bottom: 23px;
    }
  }
  &__sm-text {
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 1.5;
    padding-right: 100px;
    @include respond-max('tablet') {
      font-size: 24px;
    }
    @include respond-max('tablet-small') {
      font-size: 18px;
      padding-right: 20px;
      // line-height: 1.65;
    }
    @include respond-max('mobile') {
      font-size: 16px;
      line-height: 1.38;
      padding-right: 0;
    }
  }
  &__photo-big {
    display: block;
    max-width: 100%;
    height: auto;
    margin-top: 80px;
    margin-bottom: 54px;

    @include respond-max('tablet-small') {
      margin-top: 40px;
      margin-bottom: 39px;
    }
    @include respond-max('mobile') {
      margin-top: 9px;
    }
  }
  &__text {
    // column-count: 2;
    // column-gap: 131px;
    font-weight: normal;
    font-size: 18px;
    line-height: 1.5;
    display: flex;
    flex-wrap: wrap;
    @include respond-max('tablet-small') {
      // font-size: 16px;
      // line-height: 1.75;
      p {
        margin-bottom: 24px;
        @include respond-max('mobile') {
          margin-bottom: 16px;
        }
      }
      @include respond-min('mobile') {
        line-height: 1.55;
        padding-right: 20px;
      }
    }
    @include respond-max('mobile') {
      font-size: 16px;
      line-height: 1.38;
      padding-right: 0;
    }

    &.news-history__sm-text--row{

      @include respond-min('tablet-small') {
        margin-left: -16px;
        margin-right: -16px;
        p {
          width: 100%;
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: 16px;
          padding-right: 16px;
          &:nth-child(2n+1) {
            padding-right: 100px;
          }
          &:nth-child(2n+2) {
            padding-right: 35px;
          }
        }
      }
      @include respond-max('tablet-small') {
        padding-right: 80px;
        font-size: 16px;
        // line-height: 1.75;
      }
      @include respond-max('mobile') {
        font-size: 16px;
        line-height: 1.38;
        padding-right: 0;
      }

    }
  }
  &__loc-times {
    @include respond-max('tablet-small') {
      margin-top: 57px;
    }
  }
  &__mobile-last {
    @include respond-max('mobile') {
      order: 1;
    }
  }
  &__slider {
    overflow: hidden;
    padding-top: 58px;
    padding-bottom: 28px;
    @include respond-max('tablet-small') {
      padding-bottom: 25px;
    }
    @include respond-max('mobile') {
      padding-bottom: 10px;
    }
  }

}

.slider-history {
  display: block;
  // max-width: 100%;
  // height: auto;
  margin-top: 80px;
  margin-bottom: 54px;
  color: #666666;

  @include respond-max('tablet-small') {
    margin-top: 40px;
    margin-bottom: 39px;
  }
  @include respond-max('mobile') {
    margin-top: 9px;
  }
  &__image.slider-apartment__image {
    padding-bottom: 55%;

    @include respond-max('mobile') {
      padding-bottom: 61%;
    }
  }
  // .swiper-counter {
  //   color: #CCCCCC;
  // }
  .swiper-button-disabled {
    color: #CCCCCC;
  }
  .swiper-counter__elem:first-child {
    color: #666666;
    // min-width: 9px;
    text-align: center;
  }
  .swiper-counter__elem:last-child {
    color: #CCCCCC;
  }
}


.location-times {
  &__item {
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    @include respond-max('mobile') {
      font-size: 14px;
      line-height: 17px;
      margin-bottom: 29px;
    }
  }
  &__name {
    color: $orange1;
    text-transform: uppercase;
    line-height: 1;
    span {
      display: inline-block;
      vertical-align: middle;
    }
    .icon {
      display: inline-block;
      width: 24px;
      min-width: 24px;
      height: 24px;
      color: #999999;
      vertical-align: middle;
      margin-left: 14px;
      @include respond-max('mobile') {
        width: 16px;
        min-width: 16px;
        height: 16px;
        margin-left: 5px;
      }
    }
  }
  &__line {
    position: relative;
    -webkit-flex-basis: 0;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    height: 1px;
    background-color: #CCCCCC;
    margin-left: 20px;
    margin-right: 16px;
    min-width: 10px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 6px;
      height: 1px;
      background-color: #CCCCCC;
      transform-origin: right center;
      transform: rotate(45deg);
      @include respond-max('mobile') {
        width: 4px;
      }
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      width: 6px;
      height: 1px;
      background-color: #CCCCCC;
      transform-origin: right center;
      transform: rotate(-45deg);
      @include respond-max('mobile') {
        width: 4px;
      }
    }
    @include respond-max('mobile') {
      margin-left: 16px;
    }
  }
  &__time {
    font-weight: 500;
    text-align: right;
    color: #666666;
    white-space: nowrap;
    @include respond-max('mobile') {
      font-size: 18px;
    }
  }

}
