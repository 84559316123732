.modal {
    // position: fixed;
    // top: 100px;
    // left: 50%;
    // margin-left: -230px;
    background: $lightBlack;
    width: 100%;
    max-width: 460px;
    box-sizing: border-box;
    padding: 22px 24px 24px 24px;
    @include respond-max('mobile') {
      padding-right: 16px;
      padding-left: 16px;
      padding-top: 26px;
      padding-bottom: 24px;
    }
    z-index: 3;
    margin-left: auto;
    margin-right: auto;

    &.modal-send {
      max-width: 380px;
      @include respond-max('mobile') {
        max-width: 272px;
        .modal {
          &__title {
            font-size: 18px;
            letter-spacing: 0;
          }
          &-header {
            margin-bottom: 15px;
          }
        }
        .form-submit {
          margin-top: 25px;
        }
      }
    }

    &-header {
        position: relative;
        margin-bottom: 26px;
        @include respond-max('mobile') {
          margin-bottom: 20px;
        }
    }

    &__title {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        color: $white;
        padding-right: 30px;

        @include respond-max('mobile') {
          font-size: 24px;
          letter-spacing: 0.02em;
          line-height: 1.2;
      }
    }

    &__close {
        position: absolute;
        top: 10px;
        right: 0px;
        z-index: 2;
        cursor: pointer;
        transition: opacity ease .2s;

        @include respond-max('mobile') {
          top: 0;
        }

        &:hover {
            opacity: .75;
        }

        .icon {
            width: 24px;
            height: 24px;
        }
    }

    &-app {

        .button {
            width: 100%;
            height: 48px;
            @include respond-max('mobile') {
              height: 48px;
            }
        }
    }
    &-send {
      color: $white;
      .button {
        width: 100%;
        height: 48px;
        @include respond-max('mobile') {
          height: 48px;
        }
      }
      &__text {
        padding-right: 50px;
        @include respond-max('mobile') {
          padding-right: 0;
        }
      }
    }
    .form {
      &-agree {
        @include respond-max('mobile') {
          margin-top: 12px;
        }
      }
      &-submit {
        @include respond-max('mobile') {
          margin-top: 19px;
        }
      }
    }
}

.mfp {
  &-move-from-top {
    .mfp-close {
      display: none;
    }
    .mfp-content {
      padding: 50px 0;
    }
  }
  &-bg{
    background: #000;
    opacity: 0.5;
  }
  &-container {
    // @include respond-max('mobile') {
    @media (max-width: 320px) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
