/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "bootstrap-grid/functions";
@import "bootstrap-grid/variables";

@import "bootstrap-grid/mixins/deprecate";
@import "bootstrap-grid/mixins/breakpoints";
@import "bootstrap-grid/mixins/grid-framework";
@import "bootstrap-grid/mixins/grid";

@import "bootstrap-grid/grid";
// @import "utilities/display";
// @import "utilities/flex";
// @import "utilities/spacing";

