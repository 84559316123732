.banner-info {
  background-color: #333;
  color: #fff;
  padding: 32px 32px 32px 48px;

  @include respond-max('tablet-small') {
    padding: 38px 40px 41px 40px;
  }
  @include respond-max('mobile') {
    padding: 71px 16px 73px 16px;
  }

  &__row {
    display: flex;
    @include respond-max('mobile') {
      display: block;
    }
  }

  &__left {
    width: 465px;
    @include respond-max('mobile') {
      width: 100%;
    }

    & + .banner-info__right {
      width: 320px;
      margin-left: auto;
      padding-top: 8px;

      @include respond-max('tablet-small') {
        width: 360px;
        padding-top: 11px;
      }
      @include respond-max('mobile') {
        width: 100%;
        padding-top: 48px;
      }
    }
  }

  &__right {
    .button {
      width: 100%;
    }
  }

  &__label {
    margin-top: 100px;
    text-align: right;
    font-size: 16px;
    line-height: 19px;
    color: #999999;

    @include respond-max('tablet-small') {
      margin-top: 40px;
    }
    @include respond-max('mobile') {
      margin-top: 15px;
      text-align: left;
    }
  }

  &__title {
    font-size: 32px;
    line-height: 150%;
    font-weight: 300;
    @include respond-max('tablet-small') {
      font-size: 24px;
      padding-right: 30px;
    }
    @include respond-max('mobile') {
      font-size: 20px;
      padding-right: 0;
      line-height: 140%;
    }
  }

  &.banner-info_bg {
    background-image: url('../images/banner_info.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 782px auto;
    background-position: 118% 50%;
    min-height: 256px;
    @include respond-max('tablet-small') {
      min-height: 0;
      background-position: 99% 50%;
      background-size: auto 114%;
    }
    @include respond-max('mobile') {
      background-position: 50% 50%;
      background-size: auto 100%;
    }
  }
}
