.map {
  padding-top: 42px;
  @include respond-max('tablet-small') {
    padding-top: 40px;
  }
  @include respond-max('mobile') {
    padding-top: 31px;
  }
  &-controls {
    display: flex;
    // justify-content: space-between;
    // width: 100%;
    flex-wrap: wrap;
    margin: 0 -12px;
    @include respond-min('tablet') {
      justify-content: space-between;
    }
    @include respond-max('tablet-small') {
      flex-wrap: nowrap;
      margin: 0;
      .map-control {
        &:first-child {
          padding-left: 0;
        }
        // &:last-child {
        //   padding-right: 0;
        // }
      }
    }
    @include respond-max('mobile') {
      .map-control {
        &:first-child {
          padding-left: 16px;
        }
        &:last-child {
          padding-right: 16px;
        }
      }
    }

  }
  &-head{

    &__sizer {
      @include respond-min('tablet') {
        margin-right: 0 !important;
      }
      @include respond-max('tablet-small') {
          overflow-x: auto;
      }
      @include respond-max('mobile') {
        margin-right: -16px !important;
        margin-left: -16px;
      }
    }
  }

  &:not(.map_colors) {
    [class*="ymaps-2"][class*="-ground-pane"] {
      filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale");
      -webkit-filter: grayscale(100%);
    }
  }

  [class*="-balloon__close"] {
    display: none !important
  }

  [class*="-balloon__content"] {
    margin-right: 0 !important;
    padding: 8px !important;
  }

  &-control {
    // margin-bottom: 24px;
    padding: 0 12px;
    padding-bottom: 24px;
    @include respond-max('tablet-small') {
      padding-left: 8px;
      padding-right: 8px;
    }
    @include respond-max('mobile') {
      padding: 0 4px;
      padding-bottom: 16px;
    }
  }

  &-head {
    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &-container {
    background-color: #E5E5E5;
    height: 552px;
    position: relative;
    @include respond-max('mobile') {
      height: 360px;
    }
  }

  &-buttons {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }

  &-button {
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;

    &:hover {
      background-color: #FAFAFA;
    }

    & + .map-button {
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 4px;
        right: 4px;
        height: 1px;
        background-color: #FAFAFA;
      }
    }

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.pin-item {
  font-family: 'Gilroy';
}

.pin-item__title {
  font-weight: 500;
  font-size: 13.2544px;
  line-height: 15px;

  &:not(:last-child) {
    margin-bottom: 2px;
  }
}

.pin-item__text {
  color: #666666;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: .5px;
}

.location-map {
  overflow: hidden;
  &:not(:last-child) {
    margin-bottom: 120px;
    @include respond-max('mobile') {
      margin-bottom: 72px;
    }
  }
}

[class^="-image-with-content"] {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.24);
  border-radius: 50%;
}
