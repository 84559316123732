.button {
	border: none;
	outline: none;
	text-decoration: none;
  user-select: none;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.2;
  color: $white;
  background-color: $orange1;
  min-width: 204px;
  height: 64px;
  cursor: pointer;
  transition: 0.3s ease;
  // @include respond-max('tablet-small') {
  //   height: 56px;
  // }
  @include respond-max('mobile') {
    height: 56px;
  }
  &:disabled {
    color: $white;
    background-color: $orange3;
    cursor: default;
    &:hover {
      color: $white;
      background-color: $orange3;
    }
  }
  &:hover {
    color: $white;
    background-color: $orange2;
  }

  &__text {
    width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
  }

  &_white {
    color: #333;
    background-color: #fff;
  }
}

a.button {
  display: inline-block;
}
