.button-checkbox {
	position: relative;

	input {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		opacity: 0;
		visibility: hidden;

		&:checked ~ span {
			color: $orange1;
		}

		@include respond-min('tablet') {
			&:hover ~ span {
				color: $orange1;
			}
		}
	}

	span {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 20px;
		line-height: 24px;
		border: 1px solid #FCE9D8;

		background-color: #fff;
		color: #333333;
		cursor: pointer;
		padding: 15px 37px;
		transition: background-color 300ms ease-in-out, color 300ms ease-in-out;

    @include respond-max('tablet') {
      padding: 15px 21px;
      font-size: 18px;
    }
    @include respond-max('tablet-small') {
      padding: 15px 15px;
      font-size: 20px;
    }
    @include respond-max('mobile') {
      padding: 15px 15px;
      font-size: 18px;
    }

		svg {
			width: 24px;
			height: 24px;
			margin-right: 17px;
      @include respond-max('mobile') {
        width: 16px;
        height: 16px;
      }
		}
	}
}
