/*
@font-face {
  font-family: 'FONTNAME';
  src: url('../fonts/FONTNAME.eot');
  src: url('../fonts/FONTNAME.eot?#iefix')format('embedded-opentype'),
    url('../fonts/FONTNAME.woff2') format('woff2'),
    url('../fonts/FONTNAME.woff') format('woff'),
    url('../fonts/FONTNAME.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
*/


@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Regular.eot');
  src: local('Gilroy Regular'), local('Gilroy-Regular'),
      url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gilroy-Regular.woff') format('woff'),
      url('../fonts/Gilroy-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Medium.eot');
  src: local('Gilroy Medium'), local('Gilroy-Medium'),
      url('../fonts/Gilroy-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gilroy-Medium.woff') format('woff'),
      url('../fonts/Gilroy-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Light.eot');
  src: local('Gilroy Light'), local('Gilroy-Light'),
      url('../fonts/Gilroy-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gilroy-Light.woff') format('woff'),
      url('../fonts/Gilroy-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}


@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Gilroy-Bold.eot');
  src: local('Gilroy Bold'), local('Gilroy-Bold'),
      url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gilroy-Bold.woff') format('woff'),
      url('../fonts/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
