html,
body {
  height: 100%;
}

$black: #000000;
$lightBlack: #333333;
$white: #ffffff;
$grey: #999999;
$lightGrey: #EDEDED;

$orange1: #C68D51;
$orange2: #D4A97D;
$orange3: #E3C6A8;
$orange4: #F1E2D4;

body {
  position: relative;
  font-size: 14px;
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  color: $black;
  background-color: $white;
}

*,
:before,
:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

* {
  -webkit-locale: auto;
}

svg {
  pointer-events: none;
}

input,
textarea,
button {
  border: none;
  outline: none;
  border-radius: 0;
}

input[type='text'],
input[type='password'],
input[type='tel'],
input[type='email'],
input[type='number'],
textarea,
button {
  -webkit-appearance: none;
}


input::-webkit-credentials-auto-fill-button,
input::-webkit-contacts-auto-fill-button {
	visibility: hidden;
	pointer-events: none;
	position: absolute;
	right: 0;
}

input::-ms-reveal,
input::-ms-clear {
	width: 0;
	height: 0;
}

fieldset {
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: normal;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
}

li {
  list-style: none;
}

.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

.swiper-container {
  overflow: hidden;
}

.swiper-container,
.swiper-wrapper {
  width: 100%;
}

.swiper-button-disabled {
  color: #666;
}

.swiper-counter {
  display: flex;
  align-items: center;
  margin: 0 22px;
  color: #666666;
  min-width: 40px;

  &__elem {
    &:first-child {
      color: #ccc;
      margin-right: 5px;
      min-width: 15px;
      text-align: center;
    }
  }
}
