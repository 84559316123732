.header {
  position: relative;
  padding: 28px 0;
  overflow: hidden;
  z-index: 15;
  background-color: $white;
  @include respond-max('tablet-small') {
    padding: 26px 0;
  }
  @include respond-max('mobile') {
    padding: 8px 0;
  }
  &__col-1 {
    margin-right: 88px;
    @include respond-max('tablet-small') {
      margin: 0 auto;
    }
  }
  &__col-2{
    @include respond-max('tablet-small') {
      display: none;
    }
  }
  &__col-3 {
    display: none;
    @include respond-max('tablet-small') {
      display: block;
      position: absolute;
      top: 50%;
      right: 22px;
      margin-top: -10px;
    }
  }
  &__logo {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 50vw;
      background-image: url(../images/svg/logo-pattern.svg);
      background-size: contain;
      background-position: center right;
      background-repeat: repeat-x;
    }
  }
  &__row {
    display: flex;
    align-items: center;
  }


  &__link {
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    &:not(:last-child) {
      margin-right: 48px;
    }
  }
}
.icon-logo {
  display: block;
  width: 133px;
  min-width: 133px;
  height: 51px;
  @include respond-max('tablet-small') {
    width: 146px;
    min-width: 146px;
    height: 55px;
  }
  @include respond-max('mobile') {
    width: 104px;
    min-width: 104px;
    height: 40px;
  }
}
.icon-logo247 {
  display: block;
  width: 164px;
  min-width: 164px;
  height: 50px;
  @include respond-max('tablet-small') {
    width: 180px;
    min-width: 180px;
    height: 55px;
  }
  @include respond-max('mobile') {
    // width: 104px;
    // min-width: 104px;
    // height: 40px;
    width: 131px;
    min-width: 131px;
    height: 40px;
  }
}

.burger {
  width: 20px;
  height: 20px;
  position: relative;
  user-select: none;
  cursor: pointer;
  &__line {
    height: 2px;
    background-color: #1B1B1F;
    position: absolute;
    top: 50%;
    margin-top: -1px;
    left: 50%;
    margin-left: -10px;
    width: 20px;
    transition: .3s ease-in-out;
    transform: none;

    &:nth-child(1) {
      margin-top: -9px;
    }
    &:nth-child(3) {
      margin-top: 7px;
    }
  }
  &.burger--open {
    .burger {
      &__line {
        &:nth-child(1) {
          transform: translateY(8px) rotate(225deg);
        }
      &:nth-child(2){
          transform: rotateY(90deg);
          transition: .2s ease-in-out;
          backface-visibility: hidden;
      }
      &:nth-child(3) {
          transform: translateY(-8px) rotate(-45deg);
      }
      }
    }
    // transform: translateY(-6px) rotate(-45deg)
  }
  // &__search-btn{
  //   display: block;
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  // }
}

