.slider-apartment {
  &__image {
    position: relative;
    padding-bottom: 47.6%;

    @include respond-max('tablet-small') {
      padding-bottom: 58.2%;
    }
    @include respond-max('mobile') {
      padding-bottom: 69.2%;
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .swiper-container {
    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }
}
