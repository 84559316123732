// @include respond-min('tablet') {
//   .brands {
//     &-col {
//       // width: calc(20% - 25.6px);
//     }
//   }
// }

.brands {
  padding-bottom: 89px;
  @include respond-max('tablet-small') {
    padding-bottom: 106px;
  }
  @include respond-max('mobile') {
      padding-bottom: 72px;
  }
  &-top {
    &:not(:last-child) {
      margin-bottom: 40px;
      @include respond-max('tablet-small') {
        margin-bottom: 30px;
      }
      @include respond-max('mobile') {
        margin-bottom: 16px;
      }
    }
    @include respond-max('tablet-small') {
        margin-left: -40px;
        margin-right: -40px;
    }
    @include respond-max('mobile') {
        margin-left: -16px;
        margin-right: -16px;
    }
  }

  &-image {
    display: block;
    margin-bottom: 31px;
    @include respond-max('tablet-small') {
      margin-bottom: 11px;
    }
    @include respond-max('mobile') {
      margin-bottom: 0;
    }
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &-grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -16px;
    margin-right: -16px;
    @include respond-max('mobile') {
      margin-left: -8px;
      margin-right: -8px;
    }
  }
  &-col {
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    flex: 0 0 20%;
    max-width: 20%;
    @include respond-max('tablet-small') {
      flex: 0 0 25%;
      max-width: 25%;
    }
    @include respond-max('mobile') {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}
