.privacy {
  position: relative;
  margin: 0;
  padding: 70px 0;
  padding-bottom: 70px;
  @include respond-max('tablet') {
    padding-top: 50px;
    padding-bottom: 55px;
  }
  @include respond-max('tablet-small') {
    padding-bottom: 45px;
    padding-top: 35px;
  }
  @include respond-max('mobile') {
    padding-top: 30px;
    padding-bottom: 25px;
  }
  &__title {
    display: block;
    font-size: 42px;
    font-weight: 300;
    line-height: 1.2;
    text-transform: uppercase;
    color: #C68D51;
    margin: 0;
    margin-bottom: 60px;
    @include respond-max('tablet-small') {
      margin-bottom: 40px;
    }
    @include respond-max('mobile') {
      font-size: 26px;
      margin-bottom: 30px;
    }
    @media (max-width: 320px) {
      font-size: 22px;
      // font-weight: 400;
      // margin-bottom: 30px;
    }
  }
  &__text {
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;

    @include respond-max('mobile') {
      line-height: 1.4;
    }

    p {
      margin: 0;
      margin-bottom: 16px;
    }
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      padding-left: 0;
      margin-bottom: 16px;
      list-style-position: inside;
      list-style-type: disc;
    }
    // p > strong {
    //   display: block;
    //   margin-top: 26px;
    // }
    strong {
      font-weight: 700;
    }
  }

}
