.location-slider {
  .swiper-slide {
    width: 256px;
    display: flex;
    height: auto;
    @include respond-max('tablet-small') {
      width: 215px;
    }
    @include respond-max('mobile') {
      width: 136px;
    }
  }
  .swiper-container {
    overflow: visible;
  }
  &__arrow {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    padding: 6px 0;
    cursor: pointer;
    color: #333333;
    transition: 0.2s 0s;
    outline: 0;
    &.swiper-button-disabled {
      color: #CCCCCC;
    }
    &.swiper-prev {
      margin-right: 21px;
    }
    .icon {
      width: 48px;
      height: 13px;
    }
  }
  &__nav {
    margin-top: 20px;
    user-select: none;
    outline: 0;
    * {
      outline: 0;
    }
    @include respond-max('tablet-small') {
      margin-top: 16px;
    }
    @include respond-max('mobile') {
      margin-top: 11px;
      text-align: center;
    }
  }
}

.location-slide {
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 20px;
  user-select: none;
  position: relative;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.5;
  color: $black;
  display: flex;

  @include respond-max('tablet-small') {
    font-size: 16px;
  }
  @include respond-max('mobile') {
    font-size: 12px;
  }
  &__time {
    font-style: normal;
    font-weight: 700;
    // font-size: 56px;
    line-height: 1;
    text-transform: uppercase;
    color: $orange1;
    margin-bottom: 26px;
    @include respond-max('mobile') {
      margin-bottom: 9px;
    }
    span {
      font-size: 24px;
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
      padding-top: 6px;
      @include respond-max('tablet-small') {
        font-size: 18px;
        padding-top: 4px;
      }
      @include respond-max('mobile') {
        font-size: 14px;
      }
      &.location-slide__time-num {
        font-size: 56px;
        margin-right: 18px;
        padding-top: 0;
        @include respond-max('tablet-small') {
          font-size: 48px;
          margin-right: 16px;
        }
        @include respond-max('mobile') {
          font-size: 32px;
          margin-right: 8px;
        }
      }
    }
  }
  &__movement {
    color: #999999;
  }
  // &__place {
  // }

  &__box {
    width: 100%;
    border: 1px solid $orange1;
    padding: 51px 20px;
    position: relative;
    min-height: 256px;
    @include respond-max('tablet-small') {
      padding: 43px 10px;
      min-height: 215px;
    }
    @include respond-max('mobile') {
      padding: 22px 5px 20px 5px;
      min-height: 140px;
    }
  }

  &__icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    padding: 0 12px;
    margin-left: -32px;
    background-color: #fff;
    @include respond-max('tablet-small') {
      bottom: 4px;
      padding: 0 11px;
      margin-left: -27px;
    }
    @include respond-max('mobile') {
      bottom: 9px;
      padding: 0 6px;
      margin-left: -18px;
    }
  }
  .icon {
    display: block;
    width: 40px;
    height: 40px;
    @include respond-max('tablet-small') {
      width: 32px;
      height: 32px;
    }
    @include respond-max('mobile') {
      width: 24px;
      height: 24px;
    }
  }
}
