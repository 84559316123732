.info-location {
  position: relative;
  background-color: $orange1;
  color: $white;
  padding-top: 118px;
  padding-bottom: 80px;

  @include respond-max('tablet-small') {
    padding-top: 93px;
    padding-bottom: 58px;
  }
  @include respond-max('mobile') {
    padding-top: 46px;
    padding-bottom: 48px;
  }

  &__border-block {
    border: 1px solid $orange3;
    padding: 56px 39px 60px 39px;
    margin-top: 2px;
    @include respond-max('tablet-small') {
      text-align: center;
      padding-top: 36px;
      padding-bottom: 38px;
      margin-bottom: 54px;
    }
    @include respond-max('mobile') {
        padding-top: 30px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 31px;
        margin-bottom: 31px;
    }
  }
  &__border-title {
    font-size: 64px;
    font-weight: bold;
    margin-bottom: 14px;
    @include respond-max('mobile') {
      font-size: 48px;
      margin-bottom: 11px;
    }
    sup {
      top: -0.7em;
      font-size: 60%;
    }
  }
  &__border-text {
    font-size: 16px;
    line-height: 1.5;
    color: $orange4;
    @include respond-max('tablet-small') {
      font-size: 20px;
    }
    @include respond-max('mobile') {
      font-size: 16px;
      line-height: 1.4;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 1.5;
    @include respond-max('mobile') {
      font-size: 16px;
      line-height: 1.4;
    }
    p {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 27px;
        @include respond-max('mobile') {
          margin-bottom: 22px;
        }
      }
    }
  }
}
