@include respond-min('tablet') {
  .apartment {
    &-flex {
      display: flex;
    }

    // &-left {
    //   width: 365px;

    //   & + .apartment-right {
    //     width: calc(100% - 365px);
    //     padding-left: 40px;
    //     max-width: 660px;
    //     margin-left: auto;
    //   }
    // }
  }
}

.apartment {
  &:not(:last-child) {
    margin-bottom: 58px;
    @include respond-max('mobile') {
      margin-bottom: 36px;
    }
  }

  &-info {
    padding: 62px 45px;
    border: 1px solid $orange1;
    color: $orange1;

    @include respond-max('tablet-small') {
      padding: 38px 45px 40px 45px;
    }

    @include respond-max('mobile') {
      padding: 30px 21px 32px 21px;
    }
  }

  &-text {
    font-size: 18px;
    line-height: 150%;

    @include respond-max('tablet-small') {
      font-size: 16px;
      padding-right: 80px;
    }

    @include respond-max('mobile') {
      padding-right: 0;
      line-height: 138%;
    }

    &:not(:last-child) {
      margin-bottom: 63px;
      @include respond-max('tablet-small') {
        margin-bottom: 34px;
      }
      @include respond-max('mobile') {
        margin-bottom: 33px;
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &-button {
    .button {
      width: 100%;
    }
    &.apartment-button--desktop {
      @include respond-max('tablet-small') {
        display: none;
      }
    }
    &.apartment-button--mobile {
      margin-top: 40px;
      @include respond-min('tablet-small') {
        display: none;
      }
      @include respond-max('mobile') {
        margin-top: 32px;
      }
    }
  }
}
