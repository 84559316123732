.footer {
    @include respond-min('tablet-small') {
      border-top: $lightGrey 1px solid;
      background: linear-gradient(90deg, rgba(51,51,51,1) 50%, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 50%);
    }
    @include respond-min('tablet') {
      background: linear-gradient(90deg, rgba(51,51,51,1) 50%, rgba(0,0,0,1) 50%, rgba(255,255,255,0) 50%);
    }
    &-form {
        background: $lightBlack url('../images/svg/footer-back.svg') no-repeat right top;
        // padding: 79px 0;
        padding: 63px 0 80px 0;
        width: 100%;
        @include respond-max('tablet-small') {
          // padding: 56px 40px;
          padding: 40px 40px 56px 40px;
          width: auto;
          margin: 0 -40px;
        }
        @include respond-max('mobile') {
          margin: 0 -16px;
          width: auto;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 24px;
          padding-bottom: 40px;
        }
    }
    &__col-1 {
      display: flex;
    }
    &__col-2 {
      display: flex;
    }

    .form {

        &-input, &-textarea {
            position: relative;

            &:not(:first-child) {
                margin-top: 16px;
            }

            &.is-error {

                input, textarea {
                    border-color: red;
                }
            }
        }

        &-feedback {
          @include respond-min('tablet') {
            max-width: 565px;
          }
          @include respond-max('tablet') {
            padding-right: 70px;
          }
          @include respond-max('tablet-small') {
            padding-right: 0;
            display: flex;
            max-width: none;
            flex-wrap: wrap;
            margin-left: -8px;
            margin-right: -8px;
          }

            .button {
                width: 253px;
                @include respond-max('mobile') {
                  width: 100%;
                }
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        background: $white;
        box-sizing: border-box;
        padding: 79px 0 81px 0;
        width: 100%;
        @include respond-max('tablet-small') {
          padding: 56px 0 41px 0;
        }

        @include respond-max('mobile') {
          padding: 48px 0 41px 0;
        }

        &-bot {
          margin-top: auto;
          padding-top: 40px;
          @include respond-max('tablet-small') {
            padding-top: 53px;
          }
          @include respond-max('mobile') {
            padding-top: 32px;
            line-height: 1.4;
          }
        }

        &-top {
          @include respond-max('tablet-small') {
            @include respond-min('mobile') {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              .footer-call {
                flex: 0 0 100%;
              }
            }
          }
        }
    }

    &-contacts {
        margin-top: 43px;
        font-size: 18px;
        line-height: 28px;
        @include respond-max('tablet-small') {
          margin-top: 0;
        }
        @include respond-max('mobile') {
          margin-top: 27px;
        }

        &__address {
            color: #000;
        }

        &__email {
            font-size: 18px;
            color: #000;

            span {
                color: #666;
            }

            a {
                color: #000;
                text-decoration: none;
            }
        }
    }

    &-call {
        margin-top: 64px;

        @include respond-max('tablet-small') {
          margin-top: 39px;
        }
        @include respond-max('mobile') {
          margin-top: 49px;
        }

        &__title {
            font-size: 16px;
            line-height: 28px;
            color: $lightBlack;
            text-transform: uppercase;
            margin-bottom: 7px;
        }

        &__phone {
            font-size: 40px;
            line-height: 48px;
            color: $orange1;
            margin-top: 5px;

          @include respond-max('mobile') {
            font-size: 32px;
            line-height: 1.2;
          }
        }
    }

    &-copyright {
        font-size: 16px;
        color: $lightBlack;
    }

    &-rights {
        font-size: 12px;
        line-height: 16px;
        color: #666;
        margin-top: 27px;

        @include respond-max('tablet-small') {
          margin-top: 29px;
          max-width: 490px;
        }
        @include respond-max('mobile') {
          margin-top: 17px;
          max-width: 290px;
          font-size: 10px;
        }
    }
    &-logo {
      img {
        max-width: 100%;

        @include respond-max('mobile') {
          // width: 189px;
          width: 258px;
        }
      }

    }

}



// @include respond-max('tablet') {
// 	.footer {
// 	}
// }
