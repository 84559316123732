.map-impulse {
  position: relative;
  padding: 56px 0;
  padding-bottom: 120px;
  @include respond-max('tablet-small') {
    padding-bottom: 30px;
    padding-top: 48px;
  }
  @include respond-max('mobile') {
    padding-top: 0;
  }
  &__map {
    position: relative;
    // height: 490px;
    height: 588px;
    width: 100%;
    overflow: hidden;
    @include respond-max('tablet-small') {
      // height: 608px;
      height: 555px;
    }
    @include respond-max('mobile') {
      // height: 438px;
      // &:before {
      //   content: "";
      //   display: block;
      //   position: absolute;
      //   top: 0;
      //   left: 0;
      //   right: 0;
      //   bottom: 0;
      //   width: auto;
      //   height: auto;
      //   background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 63.18%, #FFFFFF 80.23%);
      //   z-index: 2;
      //   pointer-events: none;
      // }
      // height: 310px;
      height: 438px;
      margin-bottom: -30px;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: auto;
        height: auto;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 63.18%, #FFFFFF 80.23%);
        z-index: 2;
        pointer-events: none;
        // height: 40px;
        @include respond-max('mobile') {
          background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 53.18%, #FFFFFF 70.23%);
        }
      }
    }
  }
  &__map-sizer {
    display: block;
    width: 1414px;
    height: auto;
    position: relative;
    left: -169px;
    top: -337px;
    @include respond-max('tablet') {
      left: -269px;
    }
    @include respond-max('tablet-small') {
      left: -394px;
      top: -392px;
    }
    @include respond-max('mobile') {
      width: 796px;
      top: -191px;
      // left: -278px;
      left: 50%;
      margin-left: -422px;
    }
  }
  &__map-sizer-2 {
    display: block;
    // width: 1414px;
    height: auto;
    width: 2044px;
    // height: 1444px;
    position: relative;
    left: -349px;
    top: -436px;
    @include respond-max('tablet') {
      left: -444px;
    }
    @include respond-max('tablet-small') {
      left: -474px;
      top: -429px;
      width: 1808px;
    }
    @include respond-max('mobile') {
        width: 886px;
        top: -158px;
        left: 50%;
        margin-left: -403px;
    }
  }
  &__map-image {
    display: block;
    width: 100%;
    height: auto;
  }
  &__size-wrapper {
    @include respond-max('mobile') {
      // margin: 0 -16px;
      width: auto;
      margin: 0;
      // padding-left: 16px;
      // padding-right: 16px;
    }
  }
  &__left-col {
    @include respond-max('tablet') {
      @include respond-min('mobile') {
        min-width: 215px;
        padding-top: 2px;
      }
    }
  }
  &__row {
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: space-between;
    // width: 950px;
    // margin-left: -460px;
    // margin-top: 32px;
    width: 1080px;
    margin-left: -631px;
    margin-top: 102px;
    /* font-family: Gilroy; */
    /* font-style: normal; */
    font-weight: normal;
    font-size: 24px;
    line-height: 1.21;
    color: #333333;
    z-index: 2;

    @include respond-max('tablet') {
      width: 870px;
      margin-left: -535px;
    }
    @include respond-max('tablet-small') {
      width: 506px;
      margin-left: -345px;
      margin-top: 207px;
    }
    @include respond-max('mobile') {
      display: block;
      width: 270px;
      margin-left: 0;
      margin-top: 0;
      margin: 0;
      font-size: 14px;
      top: 453px;
      left: 50%;
      // margin-left: -176px;
          margin-left: calc(-40px - 50vw);
          width: 100vw;
          padding: 0 24px;
    }
    ul{
      &.map-impulse__ul-left {
        @include respond-min('mobile') {
          li {
            padding-right: 38px;
            padding-left: 0;
            &:before {
              left: auto;
              right: 0;

            }
            &.active {
              &:before {
                margin-right: -17px;
                @include respond-max('tablet-small') {
                  // margin-right: 0;
                }
                margin-left: 0;
              }
            }
          }
        }
      }
      li {
        position: relative;
        margin: 0;
        padding: 0;
        padding-left: 32px;
        user-select: none;
        cursor: pointer;
        transition: 0.3s 0s ease;
        @include respond-max('mobile') {
          br {
            display: none;
          }
          margin-bottom: 16px;
        }
        &:hover {
          color: $orange1;
        }
        &:not(:last-child){
          margin-bottom: 56px;
          @include respond-max('tablet-small') {
            margin-bottom: 25px;
          }
          @include respond-max('mobile') {
            margin-bottom: 16px;
          }
        }
        &.active {
          color: $orange1;
          &:before {
            width: 32px;
            height: 32px;
            background-image: url(../images/svg/map-marker-active.svg);
            margin-top: -15px;
            margin-right: 0;
            margin-left: -17px;
            @include respond-max('mobile') {
              margin-left: -8px;
            }
          }
        }
        &:before {
          content: "";
          position: absolute;
          // top: 50%;
          top: 15px;
          left: 0;
          width: 16px;
          height: 16px;
          margin-top: -8px;
          background-position: center;
          background-image: url(../images/svg/map-marker.svg);
          background-size: contain;

          @include respond-max('mobile') {
            top: 50%;
          }
        }
      }
    }
  }
  &__map-point {
    display: block;
    position: absolute;
    // top: 0;
    // left: 0;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 24px;
    border: 1px solid $white;
    background-color: #B87026;
    margin-top: -12px;
    margin-left: -12px;
    border-radius: 50%;
    transform: none;
    box-shadow: 0 0 0 11px rgba(184, 112, 38, 0.3);
    // animation: pulse 1.5s infinite;
    animation: none;
    transform-origin: center;
    animation-delay: 0ms;
    @include respond-max('tablet') {
      width: 16px;
      height: 16px;
      margin-top: -8px;
      margin-left: -8px;
      box-shadow: 0 0 0 9px rgba(184,112,38,.3);
      z-index: 1;
    }
    @include respond-max('mobile') {
      width: 10px;
      height: 10px;
      margin-top: -5px;
      margin-left: -5px;
      box-shadow: 0 0 0 6px rgba(184,112,38,.3);
      z-index: 1;
    }
    &.active {
      animation: pulse 1.5s infinite;
      z-index: 2;
    }
    &.map-impulse__map-point--1 {
      top: 47.2%;
      left: 49%;
    }
    &.map-impulse__map-point--2 {
      top: 51.3%;
      left: 41%;
    }
    &.map-impulse__map-point--3 {
      top: 49.3%;
      left: 42.8%;
    }
    &.map-impulse__map-point--3-2 {
      top: 48.3%;
      left: 49.4%;
    }
    &.map-impulse__map-point--3-3 {
      top: 50.4%;
      left: 45.3%;
    }
    &.map-impulse__map-point--3-4 {
      top: 54.7%;
      left: 48.8%;
    }
    &.map-impulse__map-point--4 {
      top: 47%;
      left: 47.7%;
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 24px rgba(184, 112, 38, 0.3);
    @include respond-max('tablet-small') {
      box-shadow: 0 0 0 20px rgba(184, 112, 38, 0.3);
    }
  }
    100% {
    transform: scale(.9);
    box-shadow: 0 0 0 11px rgba(184, 112, 38, 0.3);
  }
}
@include respond-max('tablet-small') {
  @keyframes pulse {
    0% {
      transform: scale(.9);
    }
    70% {
      transform: scale(1);
        box-shadow: 0 0 0 14px rgba(184, 112, 38, 0.3);
    }
      100% {
      transform: scale(.9);
      box-shadow: 0 0 0 8px rgba(184, 112, 38, 0.3);
    }
  }
}
@include respond-max('mobile') {
  @keyframes pulse {
    0% {
      transform: scale(.9);
    }
    70% {
      transform: scale(1);
        box-shadow: 0 0 0 10px rgba(184, 112, 38, 0.3);
    }
      100% {
      transform: scale(.9);
      box-shadow: 0 0 0 5px rgba(184, 112, 38, 0.3);
    }
  }
}
