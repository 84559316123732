.form {

    &-input, &-textarea {
        position: relative;

        // &:not(:first-child) {
        //     margin-top: 16px;
        // }
        margin-top: 16px;

        &.is-error {

            input, textarea {
                border-color: red;
            }
        }
    }
    @include respond-max('tablet-small') {
      &-col {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        padding-left: 8px;
        padding-right: 8px;
      }
      &-mobile-col {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @include respond-max('mobile') {
      &-mobile-col {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    .form-label {
        position: absolute;
        top: 24px;
        left: 23px;
        font-size: 16px;
        pointer-events: none;
        color: $lightGrey;
        transition: all ease-in .1s;
        @include respond-max('tablet-small') {
          left: 24px;
        }
        @include respond-max('mobile') {
          left: 17px;
          top: 15px;
          letter-spacing: 0.01em;
          font-size: 14px;
        }

        &.active {
            top: 14px;
            font-size: 12px;
            color: $grey;

            @include respond-max('mobile') {
              top: 9px;
              font-size: 10px;
            }
        }
    }

    input[type='text'], textarea {

        &:focus ~ .form-label {
            top: 14px;
            font-size: 12px;
            color: $grey;
            @include respond-max('mobile') {
              top: 9px;
              font-size: 10px;
            }
        }
    }

    input {
        width: 100%;
        background: $lightBlack;
        border: $grey 1px solid;
        height: 64px;
        box-sizing: border-box;
        padding: 24px 24px 0;
        font-size: 16px;
        color: $lightGrey;

        @include respond-max('tablet-small') {
          padding: 15px 23px 0;
          font-size: 15px;
          color: #EDEDED;
          font-weight: 400;
          // left: 32px;
        }
        @include respond-max('mobile') {
          height: 48px;
          padding: 9px 15px 0;
          font-size: 14px;
          letter-spacing: 0.01em;
        }
    }

    textarea {
        width: 100%;
        background: $lightBlack;
        border: $grey 1px solid;
        height: 160px;
        box-sizing: border-box;
        padding: 30px 24px 0;
        font-size: 16px;
        color: $lightGrey;
        resize: none;
        @include respond-max('mobile') {
          padding: 19px 15px 0;
          font-size: 14px;
          letter-spacing: 0.01em;
          height: 152px;
        }
    }

    &-submit {
        margin-top: 40px;
        @include respond-max('mobile') {
          margin-top: 35px;
          .button {
            width: 100%;
          }
        }
    }

    &-agree {
        font-size: 12px;
        line-height: 24px;
        color: $lightGrey;
        margin-top: 27px;

        @include respond-max('mobile') {
          margin-top: 20px;
        }

        label {
            position: relative;

            &.is-error {

                span:before {
                    border-color: red;
                }
            }
        }

        input:checked ~ span:before {
            background: url('../images/svg/checked.svg') no-repeat center center;
        }

        span {
            // display: flex;
            display: inline-block;
            position: relative;
            cursor: pointer;

             @include respond-max('mobile') {
              padding-left: 36px;
              line-height: 1.2;
            }

            &:before {
                content: '';
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                border: $grey 1px solid;
                margin-right: 12px;

                @include respond-max('mobile') {
                  position: absolute;
                  left: 0;
                  top: 50%;
                  margin-top: -12px;
                }
            }
        }

        a {
            text-decoration: underline;
            // padding: 0 5px;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .label-error {
        display: none!important;
    }



}
