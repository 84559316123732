.home-scr {
  position: relative;
  padding-top: 36px;
  padding-bottom: 1px;

  @include respond-max('tablet') {
    padding-top: 1px;
  }

  &__title {
    display: block;
    font-size: 72px;
    line-height: 1.2;
    font-weight: 300;
    color: $orange1;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 42px;
    @include respond-max('tablet-small') {
      font-size: 64px;
      padding-right: 70px;
      margin-bottom: 25px;
    }
    @include respond-max('mobile') {
      font-size: 32px;
      padding-right: 0;
      margin-bottom: 16px;
    }
  }
  &__text {
    font-size: 18px;
    line-height: 1.5;
    font-weight: 400;
    @include respond-max('tablet-small') {
      padding-right: 150px;
    }
    @include respond-max('mobile') {
      padding-right: 0;
      font-size: 14px;
      line-height: 1.45;
    }
  }
  &__photo-wrapper {
    position: relative;
    top: 44px;
    z-index: 1;
    margin-top: -4px;
    @include respond-min('tablet-small') {
      height: auto;
      padding-bottom: 100%;
    }
  }
  &__photo {
    max-width: 100%;
    height: auto;
    display: block;
    @include respond-min('tablet-small') {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: bottom center;
    }
    @include respond-max('tablet-small') {
      @include respond-min('mobile') {
        width: 100%;
        height: 58.4vw;
        object-fit: cover;
        object-position: center;
        object-position: bottom center;
      }
    }
  }
  &__btns {
    display: flex;
    flex-wrap: wrap;
    margin-top: 58px;
    @include respond-max('tablet-small') {
      display: block;
      text-align: center;
      margin-top: 50px;
    }
    @include respond-max('mobile') {
      margin-top: 32px;
    }
  }
  &__btn-wrapper {
    margin-right: 48px;
    @include respond-max('tablet-small') {
      margin-right: 0;
    }
  }
  &__btn {
    @include respond-max('tablet-small') {
      width: 100%;
    }
  }
  &__info {
    padding-top: 36px;
    padding-bottom: 100px;
    @include respond-max('tablet-small') {
      padding-top: 76px;
      padding-bottom: 16px;
    }
    @include respond-max('mobile') {
      padding-top: 0;
      padding-bottom: 31px;
    }
  }
  &__download-wrapper {
    @include respond-max('tablet-small') {
      margin-top: 24px;
    }
  }
  &__size-wrapper {
    @include respond-max('tablet-small') {
      margin-left: -40px;
      margin-right: -40px !important;
    }
    @include respond-max('mobile') {
      margin: 0 !important;
      display: none;
    }
  }
  &__mobile-box {
    @include respond-max('mobile') {
      // background-position: center;
      background-position: bottom center;
      background-size: cover;
      background-repeat: no-repeat;
      background-image:url(../images/home-02.jpg);
      margin-left: -16px;
      margin-right: -16px;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      padding-top: 87px;
      padding-bottom: 24px;
      &:before {
        content: "";
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 16.93%, rgba(0, 0, 0, 0.9) 100%);
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
      color: #FFFFFF;
      .home-scr__title{
        position: relative;
        color: #FFFFFF;
      }
      .home-scr__text{
        position: relative;
      }
    }
  }
}

.download-link {
  display: inline-block;
  color: $orange1;
  text-decoration: none;
  outline: 0;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.2;
  color: $orange1;
  text-align: left;
  @include respond-max('mobile') {
    font-size: 14px;
  }

  .icon-arrow-bottom {
    display: block;
    fill: none;
    color: $orange1;
    width: 64px;
    min-width: 64px;
    height: 64px;

    @include respond-max('tablet-small') {
      width: 48px;
      min-width: 48px;
      height: 48px;
    }
    @include respond-max('mobile') {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }
  }
  &__round {
    display: inline-block;
    vertical-align: middle;
    margin-right: 16px;
    @include respond-max('mobile') {
      margin-right: 8px;
    }
  }
  &__name {
    display: inline-block;
    vertical-align: middle;
    padding-bottom: 2px;
    @include respond-max('mobile') {
      padding-bottom: 0;
    }
    span {
      @include respond-min('tablet-small') {
        display: block;
      }
    }
  }
}
