.ul {
  font-size: 18px;
  line-height: 28px;

  @include respond-max('mobile') {
    font-size: 16px;
    line-height: 1.4;
  }

  li {
    position: relative;
    padding-left: 34px;

    @include respond-max('mobile') {
      padding-left: 26px;
    }

    b {
      font-weight: bold;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      margin-top: -7px;
      width: 12px;
      height: 12px;
      border: 1px solid $orange1;
      transform: rotate(-45deg);

      @include respond-max('mobile') {
        top: 0;
        margin-top: 7px;
        width: 11px;
        height: 11px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 32px;

      @include respond-max('tablet') {
        margin-bottom: 31px;
      }
      @include respond-max('mobile') {
        margin-bottom: 25px;
      }
    }
  }
}
