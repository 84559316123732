.section {
  padding: 118px 0 120px 0;

  @include respond-max('tablet-small') {
    padding-top: 54px;
    padding-bottom: 56px;
  }
  @include respond-max('mobile') {
    padding-top: 39px;
    padding-bottom: 40px;
  }

  &.section_dark {
    background-color: #333;
    color: #ccc;
  }

  &-head {
    // display: flex;

    &:not(:last-child) {
      margin-bottom: 45px;
      @include respond-max('tablet-small') {
        margin-bottom: 57px;
      }
      @include respond-max('mobile') {
        margin-bottom: 34px;
      }
    }
  }

  &-head__text {
    font-size: 18px;
    line-height: 150%;

    @include respond-max('tablet-small') {
      font-size: 16px;
      // line-height: 1.75;
      padding-top: 1px;
    }
    @include respond-max('mobile') {
      line-height: 1.4;
    }
  }

  &-title {
    font-size: 64px;
    line-height: 120%;
    font-weight: 300;
    color: $orange1;
    text-transform: uppercase;

    // &:not(:last-child) {
    //   max-width: 465px;
    // }

    // & + .section-head__text {
    //   width: 564px;
    // }
    @include respond-max('tablet-small') {
      font-size: 40px;
    }
    @include respond-max('mobile') {
      font-size: 32px;
      margin-bottom: 22px;
    }
  }
}

.section-banner {
  position: relative;
  min-height: 640px;
  background-color: #666;
  display: flex;
  align-items: flex-end;

  @include respond-max('tablet-small') {
    min-height: 504px;
  }

  @include respond-max('mobile') {
    min-height: 200px;
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__label {
    font-weight: 300;
    font-size: 32px;
    line-height: 150%;
    color: #D4A97D;
    text-transform: uppercase;

    // @include respond-max('tablet-small') {
    //   font-size: 20px;
    // }
    @include respond-max('mobile') {
      font-size: 20px;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
      @include respond-max('mobile') {
        margin-bottom: 0;
      }
    }
  }

  &__title {
    font-size: 160px;
    line-height: 192px;
    text-transform: uppercase;
    color: #fff;
    padding-bottom: 10px;

    @include respond-max('tablet-small') {
      font-size: 128px;
      line-height: 1;
      padding-bottom: 20px;
      font-weight: 300;
    }
    @include respond-max('mobile') {
      font-size: 72px;
      padding-bottom: 11px;
    }
  }

  .container {
    position: relative;
  }
}

.section-level {
  padding: 102px 0 57px 0;

  @include respond-max('tablet-small') {
    padding-top: 76px;
  }
  @include respond-max('mobile') {
    padding-top: 56px;
  }

  &:not(:last-child) {
    margin-bottom: 64px;
    @include respond-max('mobile') {
      margin-bottom: 15px;
    }
  }
}

.section-slider__nav {
  display: flex;
  align-items: center;
  font-size: 16px;
  user-select: none;
  outline: 0;
  @include respond-max('mobile') {
    justify-content: center;
  }


  .icon-arrow-left,
  .icon-arrow-right {
    width: 48px;
    height: 13px;
  }
  .swiper-prev, .swiper-next {
    cursor: pointer;
  }
}
